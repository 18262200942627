<template>
	<div style="padding: 10px">
		<template v-if="data.length > 0">
			<md-card class="md-card-timeline" :md-with-hover="true" v-for="item in data" :key="item.id" >
				<div class="md-subhead" style="">
					<md-avatar class="md-avatar-icon md-small" style="margin-right: 5px;">{{avatar(nombre(item))}}</md-avatar> {{nombre(item).toUpperCase()}}
				</div>
				<div class="md-subhead" style="padding-bottom: 5px">
					<md-icon class = "md-small">king_bed</md-icon> <span><strong>{{item.categoria}} </strong> | {{item.cuarto}} </span>
				</div>
				<md-card-content>
					<div>
						<md-icon>flag</md-icon>
						<span> {{lang.reserva}}: # {{item.id_reserva}} </span>
					</div>
					<div class="details">
						<div class="md-layout md-gutter ">
							<div class="md-layout-item">
								<md-icon class = "md-small ">flight_land</md-icon>
								<div class = "md-list-item-text">
									<span v-html="wrap(item.fecha_ingreso)"></span>
									<span class="label">Check in</span>
								</div>
							</div>
							<div class="md-layout-item">
								<md-icon class = "md-small">flight_takeoff</md-icon>
								<div class = "md-list-item-text">
									<span v-html="wrap(item.fecha_salida)"></span>
									<span class="label">Check out</span>
								</div>
							</div>
						</div>

						<div class="md-layout md-gutter">
							<div class="md-layout-item">	<md-icon class = "md-small">contactless</md-icon>
								<div class = "md-list-item-text">
									<span>{{item.nombre_agencia}}</span>
									<span class="label">{{lang.canal}}</span>
								</div></div>
							<div class="md-layout-item">
								<md-icon class = "md-small">info</md-icon>
								<div class = "md-list-item-text">
									<span>{{item.estatus}}</span>
									<span class="label">{{lang.estatus}}</span>
								</div>
							</div>
						</div>
					</div>

					{{item.text}}
				</md-card-content>
				<div class="md-foot">
					<router-link :to="`/Reserva/${item.id_reserva}/detalle`">
						<md-chip>
							<span style="display: flex; align-items: center">
								<span style="margin-right: 10px">{{lang.ver}}</span>
								<md-icon>arrow_forward</md-icon>
							</span>
						</md-chip>
					</router-link>
				</div>
			</md-card>
		</template>
		<md-empty-state v-else md-icon="event_available" :md-label="$store.state.lang.sin_registros" />

	</div>
</template>
<script>
	import {empty, request, avatar} from '../../../helpers';

	export default {

		computed: {
			lang() {return this.$store.state.lang},
		},
		methods:{
			wrap: function(date){
				if(!empty(date)){
					date = date.split(' ');
					date = date[0]
				}
				return date
			},
			nombre(item){
				let nombre  = (item.nombre)??'';
				let primer_apellido  = (item.primer_apellido)??'';
				return nombre + ' '+ primer_apellido;
			},
			async request(){
				let id = this.$route.params.id;
				this.$store.state.loading = true;
				let r = await request(base_url+`/resumendiario/reservashoy?view=json&jwt=${this.$store.state.jwt}`);

				if(r.ok){
					this.data = r.r.data;
				}

				this.$store.state.loading = false;
			},
			avatar(name){
				return avatar(name)
			}

		},
		mounted() {
			this.request();
		},
		data(){
			return {
				data :[]
			}
		}

	}

</script>
<style lang="scss" scoped>

	.md-card-timeline{
		margin-bottom: 10px;
	}
	.md-subhead{
		padding: 7px 7px;
		border-bottom: 1px solid #b5b5b5;
	}
	.md-foot{
		padding: 7px 7px 10px;
		text-align: right;
	}

	.details{
		div.md-layout-item{
			padding-top: 10px;
			display: flex;
			flex-wrap: nowrap;
			align-items: flex-start;
			justify-items: flex-start;
			i {
				margin-right: 4px;
			}
			.label{
				font-size: 12px;
				color: rgba(0,0,0,0.54);
			}
		}
	}

</style>
